define('modules/site/title',[
	'app',
	'jquery',
],
function(app, $) {

	return Backbone.View.extend({
		el: '.js-node',
		isMobile: false,

		initialize: function(params){
			_.bindAll(this, 'adjustTitle');

			this.createEvents();
		},

		createEvents: function() {
			var self = this;

			$(window).on('orientationchange resize', self.logicTitle());
		},

		logicTitle: function() {
			var self = this;

			self.isMobile = self.checkDocWidth();
		},

		adjustTitle: function() {
			var self = this;

			// var $headerMeta = $('')
		},

		checkDocWidth: function() {
			var isMobile = false;
			if($('body').outerWidth() < 768) {
				isMobile = true;
			}
			return isMobile;
		}

	});

});



