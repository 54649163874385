define('router',[
	// Deps
	"jquery",
	"app",
	"underscore",

	// Modules
	"modules/user/reset",
	"modules/user/password",
	"modules/site/captcha",
],

function($, app, _, resetMods, passwordStrength, siteCaptcha) {
	var reset = null;
	var pass = null;
	var sitecaptcha = null;
	// Defining the application router, you can attach sub routers here.
	var Router = Backbone.Router.extend({

		routes: {
			"node/add/eresource": "addResource",
			"node/:nid/edit": "addResource",
			"contact": "siteCaptcha",
			"user/login": "siteCaptcha",
			"*def": "index",
		},

		initialize: function() {
			_.bindAll(this, 'siteCaptcha', 'passwordStrength', 'resetMods');
			this.passwordStrength();

			//regex route, appens to routes
			this.route(/user\/reset\/(.*)$/, this.resetMods);
		},

		index: function() {
			var currentPath = window.location.pathname;

			switch (currentPath) {
				// manual routes can be added as cases here
				default: break;
			}
		},


		addResource: function() {

		},

		siteCaptcha: function() {
			sitecaptcha = sitecaptcha || new siteCaptcha();
		},
		
		passwordStrength: function() {
			pass = pass || new passwordStrength();
		},

		resetMods: function() {
			reset = reset || new resetMods();
		}
	});

	return Router;
});
