/*
       -\-                                                     
       \-- \-                                                  
        \  - -\                                                
         \      \\                                             
          \       \                                            
           \       \\                                              
            \        \\                                            
            \          \\                                        
            \           \\\                                      
             \            \\                                                 
              \            \\                                              
              \. .          \\                                  
               \    .       \\                                 
                \      .    \\                                            
                 \       .  \\                                 
                 \         . \\                                           
                 \            <=)                                         
                 \            <==)                                         
                 \            <=)                                           
                  \           .\\                                           _-
                  \         .   \\                                        _-//
                  \       .     \\                                     _-_/ /
                  \ . . .        \\                                 _--_/ _/
                   \              \\                              _- _/ _/
                   \               \\                      ___-(O) _/ _/ 
                   \                \                  __--  __   /_ /      ***********************************
                   \                 \\          ____--__----  /    \_       I AM A MOTHERFUCKING PTERODACTYL
                    \                  \\       -------       /   \_  \_     HERE TO PTERO-YOU A NEW ASSHOLE
                     \                   \                  //   // \__ \_   **********************************
                      \                   \\              //   //      \_ \_ 
                       \                   \\          ///   //          \__- 
                       \                -   \\/////////    //            
                       \            -         \_         //              
                       /        -                      //                
                      /     -                       ///                  
                     /   -                       //                      
                __--/                         ///
     __________/                            // |               
   //-_________      ___                ////  |                
           ____\__--/                /////    |                
      -----______    -/---________////        |                
        _______/  --/    \                   |                 
      /_________-/       \                   |                 
     //                  \                   /                 
                          \.                 /                 
                          \     .            /                 
                           \       .        /                  
                          \\           .    /                  
                           \                /                  
                           \              __|                  
                           \              ==/                  
                           /              //                   
                           /          .  //                    
                           /   .  .    //                      
                          /.           /                       
                         /            //                       
                         /           /
                        /          //
                       /         //
                    --/         /
                   /          //
               ////         //
            ///_________////
*/

define('modules/page/pteragrid',[
  'app',
  'jquery',
],
function(app, $) {

  return Backbone.View.extend({
    grid: $('.view-grid'),
    griditems: $('.view-grid .view-grid__item'),
    defaults: [
      [0, 768, 1024], //breakpoints
      [1, 2, 3], // columns
      [20, 20, 30] // padding
    ],

    // note, after first line of grid-items, margin-top turns into padding-top


    initialize: function(params){
      var self = this;

      if (this.grid.length !== 0) {
        _.bindAll(this, 'adjustColumns');

        var defaultBreakpoints = self.defaults[0];
        var defaultColumns = self.defaults[1];
        var defaultPadding = self.defaults[2];

        var paramBreakpoints = false;
        var paramColumns = false;
        var paramPadding = false;

        if (typeof(params) == 'object' && params.length !== 0) {
          paramBreakpoints = params[0];
          paramColumns = params[1];

          if (params.hasOwnProperty(2)) {
            paramColumns = params[2];          
          }
        }

        this.breakpoints = !paramBreakpoints ? defaultBreakpoints : paramBreakpoints;
        this.columns = !paramColumns ? defaultColumns : paramColumns;
        this.padding = !paramPadding ? defaultPadding : paramPadding;

        this.adjustColumns();
        this.createEvents();        
      }

    },

    createEvents: function() {
      var self = this;

      $(window).on('resize', function(){
        self.adjustColumns();
      });

      $(window).on('orientationchange', function() {
        self.adjustColumns();
      });
    },

    adjustColumns: function() {
      var self = this;

      var currentColumns = 0;
      var currentPadding = 0;

      for (i = 0 ; i < self.defaults.length ; i++) {
        if (self.viewportWidth() > self.breakpoints[i]) {
          currentColumns = self.columns[i];
          currentPadding = self.padding[i];
        }
      }

      var maxHeight = false;
      var maxHeightArray = [];

      self.griditems.each(function(index ,element) {
        $(element).css({
          'height': 'auto'
        });
      });

      if (currentColumns > 1) {
        var currentRow = 1;

        self.griditems.each(function(index ,element) {

          elementHeight = $(element).innerHeight();

          if(index > 2) {
            elementHeight -= (currentPadding*2);
          } else {
            elementHeight -= currentPadding;
          }

          if (elementHeight > maxHeight) {
            maxHeight = elementHeight;
          }

          if ((index+1)%currentColumns == 0 && index != 0 || index == self.griditems.length-1) {
            maxHeightArray[currentRow] = maxHeight;
            currentRow++;
            maxHeight = 0;
          }
        });

        currentRow = 1;

        self.griditems.each(function(index, element) {
          
          $(element).css({
            'height': maxHeightArray[currentRow] + 'px'
          });

          if ((index+1)%currentColumns == 0 && index != 0 || index == self.griditems.length-1) {
            currentRow++;
          }
        }); 
      }
    },

    viewportWidth: function() {
      return $(window).outerWidth();
    }


  });

});



