define('modules/filter/filter',[
	'app',
	'jquery'
],
function(app, $) {

	return Backbone.View.extend({
		el: '.js-view-filters',
		isActive: false,

		initialize: function(params){
			_.bindAll(this, 'toggleDisplay', 'createEvents', 'clearResetStyling');

			this.createEvents();
			this.clearResetStyling();
		},

		createEvents: function() {
			var toggle = this.toggleDisplay;

			$('.js-toggle-filter').click(function(){
				toggle();
			});
		},

		toggleDisplay: function() {
			$(this.el).toggleClass('is--active').find('.view__filters').toggleClass('l-container');				
			$('body').toggleClass('modal--active');

		},

		clearResetStyling: function() {
			// I need to shave some time
			$('.views-reset-button input').removeClass('btn').removeClass('btn--primary').removeClass('btn--smaller').addClass('cta-btn');
		}

	});

});

