define('modules/nav/nav',[
	'app',
	'jquery'
],
function(app, $) {

	return Backbone.View.extend({
		el: '.js-nav-main',
		events: 'resize clearDisplay',
		eventTimestamp: 0,
		isMobile: false,

		initialize: function(params){
			_.bindAll(this, 'toggleDisplay', 'clearDisplay', 'checkFrequency');
			this.isMobile = this.checkDocWidth();
      if(this.isMobile){
        $(".nav-sub").css("position","relative");
      }
			this.createEvents();
		},

		createEvents: function() {
			var self = this;

			$('.js-nav-trigger').click(function(){
				$('.js-main-navigation-container').toggleClass('is--active');
				self.toggleDisplay();
			});

			$('.js-nav-main-mobitoggle').click(function(e){
				if (self.isMobile) {
					e.preventDefault();
					$(this).toggleClass('is--active');
					$(this).siblings('.nav-sub').slideToggle();
				}
			});

			$('.js-nav-sub-mobitoggle').click(function(e){
				if (self.isMobile) {
					e.preventDefault();
					$(this).toggleClass('is--active');
					$(this).siblings('.nav-sub-child').slideToggle();
				}
			});

			$(window).resize(self.clearDisplay());
		},

		toggleDisplay: function() {
			$('.js-nav-main').toggleClass('is--active');
			this.isMobile = true;
		},

		clearDisplay: function() {
			// This removes all mobile specific classes when switching to desktop view (is this even needed?)
			var pageWidth = $(document).width();
			if (pageWidth > 996) {
				this.isMobile = false;
			}
		},

		checkFrequency: function() {
			//detect how fast the event is fired
			return 'time';
		},

		checkDocWidth: function() {
			var isMobile = false;
			if($('body').outerWidth() < 1024) {
				isMobile = true;
			}
			return isMobile;
		}

	});

});

