define('modules/user/password',[
	'app',
	'jquery'
],
function(app, $) {

	return Backbone.View.extend({

		initialize: function(params){
			_.bindAll(this, 'adjustHTML');

			if ($('.form__input-container--password').length > -1) {
				this.adjustHTML();				
			}

			this.confirmPass();
		},

		adjustHTML: function(){
			var self = this;
			var elements = $('.form__input-container--password');

			elements.each(function(index, element){
				if ($(element).hasClass('password-parent')) {
					$(element).find('.password-strength-text').insertAfter($(element).find('.password-strength-title'));
					$(element).find('div.password-strength').insertAfter($(element).find('.input--password'));
				}
				if ($(element).hasClass('confirm-parent')) {
					$(element).find('div.password-confirm').insertAfter($(element).find('.input--password'));
				}
			});
		},

		confirmPass: function() {
			var self = this;
			self.passPresent = false;

			var confirmParent = '.form__input-container--password-confirm';
			var confirmPassElement = confirmParent + ' div.password-confirm';

			$(confirmPassElement).css({'display': 'none', 'visibility': 'hidden'});

			$(confirmParent + ' input.password-confirm').focus(function(){
				$(confirmPassElement).css({'display': 'inline', 'visibility': 'visible'});				
			});
		}

	});

});

