define('modules/user/reset',[
	'app',
	'jquery'
],
function(app, $) {

	return Backbone.View.extend({
		el: '#user-profile-form',

		initialize: function(params){
			_.bindAll(this, 'modifyForm');


			this.createEvents();
		},

		createEvents: function() {
			var self = this;
			self.modifyForm();
		},

		modifyForm: function() {
			var self = this;

			$(self.el).find('#edit-field-name').hide();
			$(self.el).find('#edit-account').find('.form-item-mail').hide();
			if ($(self.el).find('.field-group-htabs-wrapper').length > -1) {
				$(self.el).find('#user_user_form_group_personal_info').insertBefore('.field-group-htabs-wrapper .horizontal-tabs .horizontal-tabs-panes');
				$(self.el).find('.horizontal-tabs .horizontal-tabs-list').remove();
				$(self.el).find('.horizontal-tabs .horizontal-tabs-panes').remove();
				$(self.el).find('.form-actions').css({
					'visibility': 'visible'
				});
			}
			$('.node__header').find('.node__title').html('<em class="primary">Change</em> your password');
			$('.node__header').find('.node__title + p').html('Please make sure your passwords match before continuing.');

		},

	});

});

