//		                                                  .--.__
//		                                                .~ (@)  ~~~---_
//		                                               {     `-_~,,,,,,)
//		                                               {    (_  ',
//		                                                ~    . = _',
//		                                                 ~-   '.  =-'
//		                                                   ~     :
//		.                                             _,.-~     ('');
//		'.                                         .-~        \  \ ;
//		  ':-_                                _.--~            \  \;      _-=,.
//		    ~-:-.__                       _.-~      [RAPTOR]   {  '---- _'-=,.
//		       ~-._~--._             __.-~                     ~---------=,.`
//		           ~~-._~~-----~~~~~~       .+++~~~~~~~~-__   /
//		                ~-.,____           {   -     +   }  _/
//		                        ~~-.______{_    _ -=\ / /_.~
//		                             :      ~--~    // /         ..-
//		                             :   / /      // /         ((
//		                             :  / /      {   `-------,. ))
//		                             :   /        ''=--------. }o
//		                .=._________,'  )                     ))
//		                )  _________ -''                     ~~
//		               / /  _ _
//		              (_.-.'O'-'.

require([
	// Application.
	'backbone',
	'underscore',
	'app',
	'jquery-private',

	'modules/filter/filter',
	'modules/nav/nav',
	// 'modules/page/select-lists',
	'modules/site/title',
	'modules/page/pteragrid',

	// Main Router.
	'router',

	// Vendor
	'jqueryPlaceholder',
],

function(Backbone, _, app, $, Filter, Navigation, siteTitle, pteraGrid, Router) {

	$(document).ready(function () {

		// var search = new Search();
		var filter = new Filter();
		var navigation = new Navigation();
		// var selectlists = new selectLists();
		var sitetitle = new siteTitle();
		var pteragrid = new pteraGrid();

		$('.input--text').placeholder();
		// $('.search__input').placeholder();


		if (typeof Drupal != 'undefined' && Drupal.ajax) {
			Drupal.ajax.prototype.commands.viewsScrollTop = null;
		}

		if ($('#edit-field-publication-date-value-value-year').length) {
			var currentYear = new Date().getFullYear();
			$('#edit-field-publication-date-value-value-year').val(currentYear).change();
		}

		app.router = new Router();
		Backbone.history.start({pushState: "pushState" in window.history});

		// is Android default browser
		var navU = navigator.userAgent;
		var isAndroidMobile = navU.indexOf('Android') > -1 && navU.indexOf('Mozilla/5.0') > -1 && navU.indexOf('AppleWebKit') > -1;
		if (isAndroidMobile)
			$('html').addClass('is--android');
	});
});

define("main", function(){});

