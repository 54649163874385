define('modules/site/captcha',[
	'app',
	'jquery',
],
function(app, $) {

	return Backbone.View.extend({
		el: 'fieldset.captcha',

		initialize: function(params){
			_.bindAll(this, 'logic');

			this.logic();
		},

		logic: function() {
			var self = this;

			if ($(self.el).length > -1) {
			 	$(self.el).find('.fieldset-description').insertAfter($(self.el).find('.captchaWrapper-mini'));
			 }
		},
	});
});
